import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";
import { logger } from "bernie-core";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { IsomorphicCarousel } from "components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";

import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkCard, UitkCardContentSection } from "@egds/react-core/cards";

import { MobileConfidenceMessagesCarouselRegionProps } from "./typings";
import { withStores } from "stores";
import { hasRenderableChildren } from "src/stores/ExperienceTemplateStore/shouldRender";
import { RegionChildren, RegionChildrenList } from "src/utils/RegionUtils";

class MobileConfidenceMessagesCarouselRegion extends React.Component<MobileConfidenceMessagesCarouselRegionProps> {
  public render() {
    return <MobileConfidenceMessagesCarouseComponent {...this.props} />;
  }
}

const MobileConfidenceMessagesCarouseComponent = (props: MobileConfidenceMessagesCarouselRegionProps) => {
  const { flexModuleModelStore, blossomComponent, templateComponent } = props;

  if (!templateComponent || !blossomComponent || !hasRenderableChildren(templateComponent, flexModuleModelStore)) {
    return null;
  }

  const {
    metadata: { id: flexComponentId },
  } = templateComponent;

  const keyHelper = new ItemKeyHelper(flexComponentId);

  if (templateComponent.children) {
    return (
      <div id={flexComponentId} className="MobileConfidenceMessagesCarouselRegion">
        <Viewport>
          <ViewSmall>
            <Carousel>
              {templateComponent.children.map((child) => (
                <CarouselCard key={keyHelper.next()}>
                  <RegionChildrenList templateComponents={[child]} blossomComponent={blossomComponent} />
                </CarouselCard>
              ))}
            </Carousel>
          </ViewSmall>
          <ViewMedium>
            <DesktopContainer>
              <RegionChildren templateComponent={templateComponent} blossomComponent={blossomComponent} />
            </DesktopContainer>
          </ViewMedium>
        </Viewport>
      </div>
    );
  }

  return null;
};

const Carousel: React.FC = (props: { children: React.ReactNode[] }) => {
  const { children } = props;
  const { formatText } = useLocalization();

  return (
    <UitkSpacing margin={{ blockstart: "three", inlinestart: "three" }}>
      <IsomorphicCarousel
        itemsVisible={{ sm: 1, md: 1, lg: 2 }}
        buttonText={{
          nextButton: formatText("carousel.item.next"),
          prevButton: formatText("carousel.item.prev"),
        }}
        pageBy={1}
        peek
      >
        {children}
      </IsomorphicCarousel>
    </UitkSpacing>
  );
};

const CarouselCard: React.FC = ({ children }) => {
  return (
    <UitkCard border>
      <UitkSpacing padding={{ inline: "two" }}>
        <UitkCardContentSection padded={false}>{children}</UitkCardContentSection>
      </UitkSpacing>
    </UitkCard>
  );
};

const DesktopContainer: React.FC = ({ children }) => {
  return (
    <div className="desktopViewContainer">
      <UitkSpacing padding={{ inline: "one" }}>
        <UitkLayoutFlex space="four" className="desktopViewSubContainer">
          {children}
        </UitkLayoutFlex>
      </UitkSpacing>
    </div>
  );
};

export default logger("MobileConfidenceMessagesCarouselRegion")(
  withStores("context", "flexModuleModelStore")(MobileConfidenceMessagesCarouselRegion)
);
